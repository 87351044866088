<template>
    <modal ref="modalCrearDriver" titulo="Crear Driver" tamano="modal-lg" no-aceptar adicional="Crear">
        <div class="row mx-0 mb-4 justify-center">
            <div class="col-auto">
                <slim-cropper 
                ref="fotoReclamo1" 
                :options="slimOptions" 
                class="border cr-pointer" 
                style="height:162px;width:162px;background-color:#F5F5F5;border-radius:6px;" 
                />
            </div>
        </div>
        <div class="row mx-0 justify-center mb-4 mt-2">
            <div class="col-5">
                <p class="text-general pl-3 f-14">Nombre</p>
                <el-input v-model="nombre" placeholder="Nombre del Driver" class="w-100" />
                <p class="text-general pl-3 f-14 mt-4">Documento de identificación</p>
                <el-input v-model="documento" placeholder="Placas" class="w-100" />
            </div>
            <div class="col-5">
                <p class="text-general pl-3 f-14">Placas del vehículo</p>
                <el-input v-model="placa" placeholder="Número de documento" class="w-100" />
                <p class="text-general pl-3 f-14 mt-4">Id_cargamos</p>
                <el-input v-model="id" placeholder="id" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombre: '',
            placa: '',
            documento: '',
            id: ''
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCrearDriver.toggle();
        }
    }
}
</script>